$size: 38px;

.component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
  background-color: rgba(#edf4fe, 0.5);
  border-radius: 19px;
  transition: background-color 0.2s;
  height: $size;

  a {
    color: white;
    text-decoration: none;
  }

  span {
    color: white;
    cursor: default;
  }

  &:hover {
    background-color: #edf4fe;
  }
}

.text {
  background: black;
  color: white;
  padding: 0 15px;
}

.button {
  opacity: 1;
  height: 100%;
  width: 50%;
  font-size: 12px;
  font-weight: 500;
}
