body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text for readability */
}

a {
  color: #4a90e2;
}

a:hover {
  text-decoration: underline;
}
