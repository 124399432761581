.App {
  text-align: center;
}

.App-logo {
  padding-left: 15px;
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: 'black'; /* Dark header background */
  min-height: 15vh; /* Adjusted to 50% of the viewport height */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}


@media (max-width: 600px) {
  .App-header {
    flex-direction: column; /* Stack them on smaller screens */
  }

  .App-header h1 {
    margin-right: 0; /* Remove margin on smaller screens */
  }

  .App-logo {
    height: 100px; /* Smaller image on smaller screens */
  }
}
